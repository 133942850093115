/* Add a Google Font link in your HTML head:
<link href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap" rel="stylesheet"> */

body, html {
  margin: 0;
  padding: 0;
 /* font-family: 'Open Sans', sans-serif;*/
  background-color: #f7f7f7; /* A light grey background */
}

.App {
  text-align: center;
  color: #333; /* Dark grey text for better readability */
}

/* Header styles */
.App-header {
  background-color: #ffffff;
  padding: 20px 0;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* subtle shadow for depth */
}

.App-header img {
  max-width: 100%;
  height: auto;
}

/* Link styles */
.App-link {
  color: #61dafb;
  text-decoration: none;
  font-weight: 600;
}

/* List styles */
.WorkshopList {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

/* Style each workshop item */
.WorkshopItem {
  background-color: #fff;
  margin: 10px auto; /* Center the items */
  padding: 20px;
  border-radius: 8px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* More pronounced shadow for depth */
  width: 80%; /* Set a max-width for desktop views */
  max-width: 400px; /* Maximum width of the items */
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
}


/* Hover effect for workshop items */
.WorkshopItem:hover {
  box-shadow: 0 6px 12px rgba(0,0,0,0.2);
}

/* Style the workshop item title */
.WorkshopItem h2 {
  color: #333;
  font-size: 1.5em; /* Larger font size for the title */
  margin-bottom: 0.5em; /* Space between title and date */
}

/* Style the workshop item date and slots */
.WorkshopItem p {
  color: #666;
  font-size: 1em;
  margin: 5px 0;
}

/* Style the header for the workshops list */
.WorkshopList-header {
  font-size: 2em;
  color: #333;
  margin-bottom: 1em; /* Space between header and items */
}

/* Add responsiveness for smaller devices */
@media (max-width: 600px) {
  .WorkshopItem {
    width: 90%; /* Allow the items to take more width on smaller screens */
  }
}

.WorkshopDetails {
  background-color: #fff; /* White background for the details card */
  border-radius: 10px; /* Rounded corners for the card */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow for depth */
  padding: 25px;
  margin: 20px auto; /* Center the card */
  width: 80%; /* Responsive width */
  max-width: 500px; /* Maximum width for larger screens */
  text-align: left; /* Align text to the left */
}

.WorkshopDetails h2 {
  color: #333; /* Darker color for the title */
  font-size: 2em; /* Larger font size for the title */
  margin-bottom: 0.5em; /* Space between title and description */
}

.WorkshopDetails p {
  color: #666; /* Darker color for paragraph text */
  line-height: 1.6; /* More readable line-height */
  margin-bottom: 1em; /* Space between paragraphs */
}

/* Styling for the 'Back' button to make it more subtle */
.WorkshopDetails .backButton {
  background-color: #f7f7f7; /* Light grey background */
  color: #333; /* Dark text */
  margin-bottom: 20px; /* Space below the button */
}


button {
  background-color: #A1ACA7;
  border: none;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 10px;
}

button:hover {
  background-color: #4fa0cb;
}
